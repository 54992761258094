import { client } from "@/config/client"
import type { Document } from "./get"
import type { ClientError } from "@educabot/fetcher"

export enum CreateDocumentErrors {
  U1 = "U1",
}

type CreateDocumentResponse = Document

export interface CreateDocumentData {
  file: string
  learningPathId: string
  objectId: string
}

export function create(data: CreateDocumentData) {
  return client
    .post<CreateDocumentResponse, CreateDocumentData>("/v1/documents", data)
    .catch((err: ClientError) => {
      if (err.code === CreateDocumentErrors.U1) {
        throw client.error(
          err,
          "No se pudo procesar tu archivo, asegurate de que el documento no sea escaneado.",
        )
      }
      throw err
    })
}
