import { client } from "@/config/client"

export interface CompleteData {
  name: string
  country: string
  studentRole: string
  career: string
  institution: string
  fileName: string
  learningPath: string
  objectId: string
}

export function complete(data: CompleteData) {
  return client.post("/v1/users/onboarding", data)
}
