import { Button } from "@/components/ui/button"
import { CircleX } from "lucide-react"
import { useNavigate } from "react-router-dom"

export default function ErrorBoundaryPage() {
  const navigate = useNavigate()

  function handleReload() {
    // Refresh actual page
    navigate(0)
  }

  return (
    <section className="flex h-full w-full flex-col items-center justify-center gap-6">
      <CircleX className="size-24 text-primary" />
      <h1 className="text-2xl font-bold text-black/80">
        Ha ocurrido un error al intentar cargar la página
      </h1>
      <Button className="mt-3" onClick={handleReload}>
        Recargar página
      </Button>
    </section>
  )
}
