import { useRef, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Card, CardContent, CardDescription, CardHeader } from "@/components/ui/card"
import { ArrowRight, FileText, Loader2 } from "lucide-react"
import { Button } from "@/components/ui/button"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import { usePresignedUrl } from "@/modules/documents/queries/use-create-presigned-url"
import { useCreateDocument } from "@/modules/documents/queries/use-create"
import useBugSnag from "@/app/hooks/useBugSnag"

export default function UploadDocumentModal() {
  const [isOpen, setIsOpen] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const { selectedLearningPath } = useLearningPaths()
  const presignedUrl = usePresignedUrl()
  const createDocument = useCreateDocument()
  const { notify } = useBugSnag()
  const uploadingDocument =
    createDocument.isPending || presignedUrl.create.isPending || presignedUrl.uploadFile.isPending

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    setSelectedFile(file)
    onToggleModal()
  }

  function onToggleModal() {
    setIsOpen(!isOpen)
  }

  function onCloseModal() {
    setIsOpen(false)
    setSelectedFile(null)
  }

  function onClickCard() {
    fileInputRef.current?.click()
  }

  async function onConfirmUpload() {
    if (!selectedFile) return
    try {
      const fileName = selectedFile.name
      const { data } = await presignedUrl.create.mutateAsync()
      await presignedUrl.uploadFile.mutateAsync({
        url: data.url,
        file: selectedFile,
      })
      await createDocument.mutateAsync({
        file: fileName,
        learningPathId: selectedLearningPath.id,
        objectId: data.objectId,
      })
      onCloseModal()
    } catch (error) {
      notify(error)
    }
  }

  return (
    <>
      <Card className="block w-full shadow">
        <CardHeader className="flex flex-col justify-between px-4 pb-2.5 pt-3 md:flex-row">
          <CardDescription className="text-sm text-black/85">
            Subí un documento y empezá a aprovechar al máximo la aplicación.
          </CardDescription>
        </CardHeader>
        <CardContent
          className="relative flex w-full cursor-pointer items-center justify-between px-4 pb-4"
          onClick={onClickCard}
        >
          <div className="relative inline-flex gap-2">
            <FileText className="size-6 text-primary" />
            <p className="text-base font-bold text-primary">Cargar documento</p>
          </div>
          <input type="file" ref={fileInputRef} className="hidden" onChange={handleFileChange} />
          <Button size="icon" variant="ghost">
            <ArrowRight className="!size-6 text-primary" />
          </Button>
        </CardContent>
      </Card>

      <Dialog
        open={isOpen}
        onOpenChange={() => {
          if (!uploadingDocument) onToggleModal()
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="leading-7">Subir Documento</DialogTitle>
            <DialogDescription>
              ¿Estás segúro que quieres subir el archivo <strong>{selectedFile?.name}</strong>?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="gap-2">
            <Button disabled={uploadingDocument} onClick={onConfirmUpload}>
              {uploadingDocument ? (
                <>
                  <Loader2 className="animate-spin" />
                  <span>Subiendo...</span>
                </>
              ) : (
                "Confirmar"
              )}
            </Button>
            <Button disabled={uploadingDocument} variant="secondary" onClick={onCloseModal}>
              Cancelar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
