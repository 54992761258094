import { useLocation } from "react-router-dom"

const regexOfRoutesWhereShowingOnlyBody =
  /^\/((flashcards|challenges|tools\/mindmaps|new-study-session)\/.+$|new-study-session)/

const useLayout = () => {
  const { pathname } = useLocation()
  const showOnlyBody = regexOfRoutesWhereShowingOnlyBody.test(pathname)
  return { showOnlyBody }
}

export default useLayout
