import type { Subtheme } from "@/services/api/learning-paths/get"
import CircularProgress from "@/components/progress/circular-progress"

interface SubThemeProps {
  subTheme: Subtheme
}

const SubTheme = ({ subTheme }: SubThemeProps) => {
  const { name = "", sessions } = subTheme ?? {}

  const lastSession = sessions?.length
    ? sessions?.reduce((prev, current) => (prev.createdAt > current.createdAt ? prev : current))
    : null

  const currentScore = lastSession?.score ?? 0

  return (
    <div className="flex cursor-pointer items-center rounded-sm border border-gray-300 bg-white p-4 hover:bg-gray-100">
      <div className="mr-4 flex items-center justify-center">
        <CircularProgress circleSize={30} progress={currentScore} />
      </div>
      <p className="text-md font-medium">{name}</p>
    </div>
  )
}

export default SubTheme
