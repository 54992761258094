import { queryClient } from "@/config/query"
import { client } from "@/config/client"
import { type StateCreator } from "zustand"
import { create } from "@/app/state"
import { persist } from "zustand/middleware"

interface User {
  id: string
  name: string
  email: string
  avatar: string
  wasOnboarded: boolean
}

interface AuthState {
  token: string
  isAuthenticated: boolean
  user: User
  logIn: (token: string, user: User) => void
  logOut: () => void
}

const userInitialState = {
  id: "",
  name: "",
  email: "",
  avatar: "",
  wasOnboarded: false,
}

const authState: StateCreator<AuthState> = (set) => ({
  token: "",
  isAuthenticated: false,
  user: userInitialState,
  logIn: (token: string, user: User) => {
    client.updateToken(token)
    set({ token, user, isAuthenticated: true })
  },
  logOut: () => {
    client.updateToken("")
    queryClient.clear()
    set({ token: "", user: userInitialState, isAuthenticated: false })
  },
})

export const useAuth = create<AuthState>()(
  persist(authState, {
    name: "auth",
    partialize: (state) => ({
      user: state.user,
      token: state.token,
      isAuthenticated: state.isAuthenticated,
    }),
    onRehydrateStorage: () => (state) => {
      if (!state?.token) return
      client.updateToken(state.token)
      state.isAuthenticated = true
    },
  }),
)
