import { type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "new-study-session",
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: NewStudySessionPage } = await import("@/pages/new-study-session")
        return { Component: NewStudySessionPage }
      },
    },
    {
      path: "by-document",
      lazy: async () => {
        const { default: NewStudySessionByDocumentPage } = await import(
          "@/pages/new-study-session/by-document"
        )
        return { Component: NewStudySessionByDocumentPage }
      },
    },
  ],
}

export default routes
