import type { SessionReview } from "@/services/api/diagnoses/create-review"
import { create } from "@/app/state"
import { persist } from "zustand/middleware"

interface State {
  sessionReview: SessionReview | null
}

interface Actions {
  setSessionReview: (sessionReview: SessionReview | null) => void
}

const initialState: State = {
  sessionReview: null,
}

export const useSessionReview = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setSessionReview: (sessionReview) => set({ sessionReview }),
    }),
    {
      name: "session-review",
    },
  ),
)
