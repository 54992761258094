import React, { useEffect, useState } from "react"
import useGetLpById from "@/modules/app/queries/use-get-lp-by-id"
import Theme from "./edit-learning-path-theme"
import Subtheme from "./edit-learning-path-subtheme"
import { Button } from "@/components/button"
import type { ListTheme } from "@/services/api/learning-paths/update"
import useUpdateLp from "@/modules/app/queries/use-update-lp"
import type { Theme as LPTheme } from "@/services/api/learning-paths/get"
import { useNavigate } from "react-router-dom"
import { ChevronLeft } from "lucide-react"
import { toast } from "sonner"
import useBugSnag from "@/app/hooks/useBugSnag"

interface EditLearningPathProps {
  id: string
}

export default function EditLearningPath({ id }: EditLearningPathProps) {
  const getLearningPath = useGetLpById(id)
  const updateLearningPath = useUpdateLp(id)
  const navigate = useNavigate()
  const [themes, setThemes] = useState<ListTheme[]>([])
  const { notify } = useBugSnag()

  const formatThemes = (themes: LPTheme[]) =>
    (themes ?? []).map((t) => ({
      subthemes: t.subthemes ?? [],
      ...t,
    }))

  useEffect(() => {
    if (getLearningPath.isSuccess) {
      setThemes(formatThemes(getLearningPath.data.themes ?? []))
    }
  }, [getLearningPath.data])

  const handleThemeNameChange =
    (themeIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setThemes((themes) =>
        themes.map((t, i) => (i == themeIndex ? { ...t, name: e.target.value } : t)),
      )
    }

  const handleSubthemeNameChange =
    (themeIndex: number, subthemeIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setThemes((themes) =>
        themes.map((t, i) =>
          i == themeIndex
            ? {
                ...t,
                subthemes: t.subthemes.map((s, i) =>
                  i == subthemeIndex ? { ...s, name: e.target.value } : s,
                ),
              }
            : t,
        ),
      )
    }

  const addTheme = () => {
    setThemes((themes) => themes.concat([{ name: "", subthemes: [] }]))
  }

  const addSubtheme = (themeIndex: number) => () => {
    setThemes((themes) =>
      themes.map((t, i) =>
        i == themeIndex ? { ...t, subthemes: t.subthemes.concat([{ name: "" }]) } : t,
      ),
    )
  }

  const removeTheme = (themeIndex: number) => () => {
    setThemes((themes) => themes.filter((_, i) => i != themeIndex))
  }

  const removeSubtheme = (themeIndex: number, subthemeIndex: number) => () => {
    setThemes((themes) =>
      themes.map((t, i) =>
        i == themeIndex ? { ...t, subthemes: t.subthemes.filter((_, i) => i != subthemeIndex) } : t,
      ),
    )
  }

  const handleOnSave = async () => {
    try {
      await updateLearningPath.mutateAsync({ themes })
      toast.success("Materia actualizada correctamente.")
      navigate("/diagnoses")
    } catch (err) {
      toast.error("Ha ocurrido un error al actualizar la materia.")
      notify(err)
    }
  }

  const isThereEmptyThemes = themes.some(
    ({ name, subthemes }) => name.trim() === "" || subthemes.some(({ name }) => name.trim() === ""),
  )

  return (
    <div className="mx-auto w-full max-w-3xl px-4">
      <div className="flex flex-col space-y-6">
        <div className="flex justify-start">
          <Button onClick={() => navigate(-1)}>
            <ChevronLeft />
            <span className="text-sm">Volver</span>
          </Button>
        </div>
        <div className="flex justify-end">
          <Button onClick={addTheme}>
            <span className="text-sm">Agregar tema</span>
          </Button>
        </div>
        <div className="space-y-6">
          {themes
            // ?.sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
            .map((theme, themeIndex) => (
              <Theme
                key={themeIndex}
                theme={theme}
                index={themeIndex}
                onNameChange={handleThemeNameChange(themeIndex)}
                onDelete={removeTheme(themeIndex)}
              >
                <div className="ml-6 mt-4 space-y-4">
                  <div className="space-y-4">
                    {theme.subthemes
                      // ?.sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
                      .map((subtheme, subthemeIndex) => (
                        <Subtheme
                          key={`subtheme-${subthemeIndex}`}
                          subtheme={subtheme}
                          onNameChange={handleSubthemeNameChange(themeIndex, subthemeIndex)}
                          onDelete={removeSubtheme(themeIndex, subthemeIndex)}
                        />
                      ))}
                  </div>
                  <Button className="w-full sm:w-auto" onClick={addSubtheme(themeIndex)}>
                    <span className="text-sm">Agregar subtema</span>
                  </Button>
                </div>
              </Theme>
            ))}
        </div>
        <div className="flex justify-end pt-4">
          <Button
            onClick={handleOnSave}
            disabled={updateLearningPath.isPending || isThereEmptyThemes}
          >
            <span className="text-sm">
              {updateLearningPath.isPending ? "Procesando..." : "Guardar"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}
