import React, { useEffect } from "react"
import { QUESTION_TYPE } from "@/services/api/learning-paths/themes/subthemes/get"
import type { SessionQuestion } from "@/services/api/learning-paths/get"
import MultipleChoiceQuestion from "./multiple-choice"
import OpenEndedAnalysisQuestion from "./open-ended-analysis-question"
import TrueFalseQuestion from "./true-false-question"
import { useQuestionTypes } from "@/modules/learning-paths/hooks/use-question-types"
import { useLearningSession } from "@/modules/learning-paths/stores/use-learning-session"
import { useCreateReview } from "@/modules/learning-paths/queries/use-create-review"
import { useCreateFeedback } from "@/modules/learning-paths/queries/use-create-feedback"
import { useSessionReview } from "@/modules/learning-paths/stores/use-session-review"
import { SessionReview } from "../session-review"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import type { CreateFeedbackResponse } from "@/services/api/diagnoses/create-feedback"
import type { CreateReviewResponse } from "@/services/api/diagnoses/create-review"
import useBugSnag from "@/app/hooks/useBugSnag"
interface QuestionTypeProps {
  currentQuestion: SessionQuestion
}

export default function QuestionType({ currentQuestion }: QuestionTypeProps): React.ReactNode {
  const { selectedSubtheme } = useLearningPaths()
  const { learningSession, setLearningSession } = useLearningSession()
  const { sessionReview, setSessionReview } = useSessionReview()
  const generateReview = useCreateReview()
  const generateFeedback = useCreateFeedback()
  const { setOpenAnswer, setSelectedOption } = useQuestionTypes({ currentQuestion })
  const { notify } = useBugSnag()
  const currentAnswer = learningSession.answers?.[learningSession.step - 1]
  const isLoading = generateFeedback.isPending || generateReview.isPending

  async function handleNextStep() {
    try {
      if (learningSession.step < 7) {
        setLearningSession({
          ...learningSession,
          step: learningSession.step + 1,
        })
      } else {
        const { data } = await generateReview.mutateAsync({
          subThemeId: selectedSubtheme.id,
          questions: learningSession.questions,
          answers: learningSession.answers,
        })
        const resp = JSON.parse(data) as CreateReviewResponse
        setSessionReview(resp.review)
      }
    } catch (error) {
      notify(error)
    }
  }

  async function handleFeedback() {
    try {
      const { data } = await generateFeedback.mutateAsync({
        questionType: currentQuestion.type,
        question: currentQuestion.question,
        userAnswer: currentAnswer.answer,
        answer: currentQuestion.answer,
      })
      const resp = JSON.parse(data) as CreateFeedbackResponse
      const { feedback, score } = resp.data

      const newAnswers = learningSession.answers.map((answer) => {
        if (answer.index === currentAnswer.index) {
          return {
            ...answer,
            feedback,
            score,
          }
        }
        return answer
      })
      setLearningSession({
        ...learningSession,
        answers: newAnswers,
      })
    } catch (error) {
      console.error("Error generating questions:", error)
    }
  }

  useEffect(() => {
    setSelectedOption(null)
    setOpenAnswer("")
  }, [currentQuestion])

  const renderQuestion = () => {
    switch (true) {
      case currentQuestion.type === QUESTION_TYPE.MULTIPLE_CHOICE:
        return (
          <MultipleChoiceQuestion
            isLoading={isLoading}
            currentQuestion={currentQuestion}
            handleNextStep={handleNextStep}
            handleFeedback={handleFeedback}
          />
        )
      case currentQuestion.type === QUESTION_TYPE.TRUE_FALSE:
        return (
          <TrueFalseQuestion
            isLoading={isLoading}
            currentQuestion={currentQuestion}
            handleNextStep={handleNextStep}
            handleFeedback={handleFeedback}
          />
        )
      case currentQuestion.type === QUESTION_TYPE.OPEN_ENDED ||
        currentQuestion.type === QUESTION_TYPE.ANALYSIS:
        return (
          <OpenEndedAnalysisQuestion
            isLoading={isLoading}
            currentQuestion={currentQuestion}
            handleNextStep={handleNextStep}
            handleFeedback={handleFeedback}
          />
        )
      default:
        return null
    }
  }

  if (sessionReview) {
    const score =
      learningSession.answers?.reduce(
        (acc: number, answer, index: number) =>
          acc +
          (index === learningSession.answers.length - 1
            ? (answer.score ?? 0) * 2
            : (answer.score ?? 0)),
        0,
      ) ?? 0 / learningSession.answers.length + 1
    return (
      <div className="px-2 sm:p-12">
        <SessionReview score={Math.trunc(score)} />
      </div>
    )
  }

  return renderQuestion()
}
