import React from "react"
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer"
import { Button } from "@/components/ui/button"
import usePlanModal from "../stores/use-plan-modal"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import PlanSelectionStep from "./plan-selection-step"
import { X } from "tabler-icons-react"
import { ScrollArea } from "@/components/ui/scroll-area"
import features from "../constants/features"

interface PlanDrawerProps {
  children: React.ReactNode
}

export default function PlanDrawer({ children }: PlanDrawerProps) {
  const { isOpen, step, setClose, setStep } = usePlanModal()

  const handleNext = () => {
    setStep(1)
  }

  const handleDialogClose = (open: boolean) => {
    setClose()
    if (!open) {
      setStep(0)
    }
  }

  return (
    <>
      {step === 0 && (
        <Drawer open={isOpen} onOpenChange={handleDialogClose}>
          {children}
          <DrawerContent withBar={false} className="rounded-t-3xl px-3">
            {step === 0 && <div className="bg-plan-modal absolute inset-0 z-0" />}
            <DrawerHeader className="flex flex-col items-center gap-2 px-0 pt-12">
              <DrawerTitle className="text-center text-2xl font-bold sm:text-3xl">
                Obtené Tich Premium
              </DrawerTitle>
              <DrawerDescription className="px-4 text-left text-lg font-medium text-black/85">
                ¡Los usuarios premium tienen <span className="font-semibold">5 veces</span> más
                probabilidades de aprobar sus exámenes!
              </DrawerDescription>
            </DrawerHeader>
            <section className="mb-12 mt-8 grid gap-6">
              {features.map((feature) => (
                <div key={feature.name} className="flex items-center gap-4">
                  <img src={feature.icon} alt={feature.name} className="size-10 object-contain" />
                  <div>
                    <h3 className="text-lg font-semibold text-black/85 sm:text-base">
                      {feature.name}
                    </h3>
                    <p className="text-sm text-black/60 sm:text-sm">{feature.description}</p>
                  </div>
                </div>
              ))}
            </section>
            <DrawerFooter className="relative z-10 px-0">
              <Button size="lg" onClick={handleNext}>
                Ver planes
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
      {step === 1 && (
        <Dialog open={isOpen} onOpenChange={handleDialogClose}>
          {children}
          <DialogContent
            withClose={false}
            className="flex h-screen min-w-full flex-col justify-between rounded-none"
          >
            <DialogHeader className="gap-4">
              <DialogClose className="size-fit self-end" onClick={() => handleDialogClose(false)}>
                <X className="size-6 text-black/60" />
                <span className="sr-only">Close</span>
              </DialogClose>
              <DialogTitle className="text-lg font-normal text-black/85">
                Elegí un plan y prepararte con los mejores métodos de estudio.
              </DialogTitle>
            </DialogHeader>
            <ScrollArea className="my-4 size-full">
              <div className="flex">
                <PlanSelectionStep />
              </div>
            </ScrollArea>
            <DialogFooter>
              <p className="text-center text-xs text-black/60">
                <strong>Podés cancelar tu plan en cualquier momento sin costos adicionales.</strong>{" "}
                Tu suscripción se renovará automáticamente por el mismo período de tiempo si no es
                cancelada al menos 24 horas antes de que termine el período en curso.
              </p>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
