import type { User } from "@/services/api/users/getAll"
import UsersTable from "@/modules/admin/components/users-table"
import AdminMetrics from "@/modules/admin/components/admin-metrics"
import AdminEditUserSheet from "@/modules/admin/components/edit-user"
import { useState } from "react"
import { toast } from "sonner"
import useGetUsers from "@/modules/admin/queries/use-get-users"
import useEditUser from "@/modules/admin/queries/use-edit-user"
import useAdminMetrics from "@/modules/admin/queries/use-admin-metrics"
import useLoginAsUser from "@/modules/auth/queries/use-login-as-user"
import type { EditUserSchema } from "@/modules/admin/validations/edit-user"
import { useDebounceValue } from "usehooks-ts"

export default function AdminPage() {
  const loginAsMutation = useLoginAsUser()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")
  const [debouncedValue, setDebouncedValue] = useDebounceValue("", 500)
  const [pagination, setPagination] = useState<{ offset: number; limit: number }>({
    offset: 0,
    limit: 10,
  })
  const [plan, setPlan] = useState<string>("")

  const [user, setUser] = useState<User>({
    id: 0,
    avatar: "",
    name: "",
    email: "",
    country: "",
    university: "",
    career: "",
    lives: 0,
    plan: "FREE",
    events: [],
  })

  const { data: metrics } = useAdminMetrics()
  const { mutateAsync, isPending } = useEditUser()
  const {
    data: users,
    isFetching,
    refetch: refetchGetUsers,
  } = useGetUsers({
    search: debouncedValue,
    offset: pagination.offset,
    limit: pagination.limit,
    ...(plan !== "ALL" && { plan }),
  })

  function handleOnPageChange(offset: number, limit: number) {
    setPagination({ offset, limit })
  }

  function handleOnSearch(text: string) {
    setSearch(text)
    setDebouncedValue(text)
  }

  function handleOnEditOpen(user: User) {
    setUser(user)
    setOpen(true)
  }

  function handleOnPlanChange(plan: string) {
    setPlan(plan)
  }

  async function handleOnLoginAs(user: User) {
    try {
      await loginAsMutation.mutateAsync(user.email)
      window.location.reload()
      window.location.href = "/"
    } catch {
      toast.error("No se pudo iniciar sesión como el usuario")
    }
  }

  async function handleOnEditSave(userId: number, values: EditUserSchema) {
    try {
      await mutateAsync({
        id: userId,
        ...values,
      })
      toast.success("Usuario editado correctamente")
      refetchGetUsers()
    } catch {
      toast.error("No se pudo editar el usuario")
    }

    setOpen(false)
  }

  return (
    <div className="flex w-full flex-col gap-4 px-12 pt-6">
      <p className="px-2 py-2 text-2xl font-bold text-primary">Panel de administrador</p>
      <AdminEditUserSheet
        open={open}
        user={user}
        setOpen={setOpen}
        onSave={handleOnEditSave}
        isLoading={isPending}
      />
      <AdminMetrics total={metrics?.total ?? 0} totalPremium={metrics?.totalPremium ?? 0} />
      <UsersTable
        search={search}
        isLoading={isFetching}
        total={users?.total ?? 0}
        users={users?.users ?? []}
        onEdit={handleOnEditOpen}
        onLoginAs={handleOnLoginAs}
        onSearchChange={handleOnSearch}
        onPageChange={handleOnPageChange}
        onPlanChange={handleOnPlanChange}
      />
    </div>
  )
}
