import generate from "./generate"
import remove from "./delete"
import save from "./save"
import update from "./update"
import getAll from "./getAll"

export const mindmaps = {
  generate,
  delete: remove,
  save,
  update,
  getAll,
}
