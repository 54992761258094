import getUserChallenges, {
  type GetUserChallengesParams,
} from "@/services/api/challenges/get-user-challenges"
import { useQuery } from "@tanstack/react-query"

const useGetUserChallenges = (params: GetUserChallengesParams) => {
  const query = useQuery({
    queryKey: ["userChallenges", params],
    queryFn: () => getUserChallenges(params),
  })

  return query
}

export default useGetUserChallenges
