import { useEffect, useState } from "react"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  SelectGroup,
} from "@/components/ui/select"
import { useLearningPaths } from "../stores/use-lp-store"

export default function LearningPathsSelector() {
  const [selectedPathId, setSelectedPathId] = useState("")
  const { learningPaths, selectedLearningPath, setSelectedLearningPath } = useLearningPaths()

  useEffect(() => {
    const initialSubject =
      selectedLearningPath.id !== "" ? selectedLearningPath : learningPaths.at(-1)

    if (initialSubject) {
      setSelectedPathId(initialSubject.id)
      setSelectedLearningPath(initialSubject)
    }
  }, [])

  const handleValueChange = (id: string) => {
    setSelectedPathId(id)
    const result = learningPaths.find((path) => path.id === id)
    if (result) {
      setSelectedLearningPath(result)
    }
  }

  return (
    <Select value={selectedPathId} onValueChange={handleValueChange}>
      <SelectTrigger className="relative w-[180px]">
        <SelectValue placeholder="Selecciona una materia">{selectedLearningPath.name}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {learningPaths.map((path) => (
            <SelectItem key={path.id} value={path.id}>
              {path.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
