import { useEffect } from "react"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { useQuestionTypes } from "@/modules/learning-paths/hooks/use-question-types"
import { useLearningSession } from "@/modules/learning-paths/stores/use-learning-session"
import type { SessionQuestion } from "@/services/api/learning-paths/get"
import { QUESTION_TYPE } from "@/services/api/learning-paths/themes/subthemes/get"
import { Loader2 } from "lucide-react"
import Latex from "react-latex"
import { Card, CardContent, CardFooter } from "@/components/ui/card"

interface OpenEndedAnalysisQuestionProps {
  currentQuestion: SessionQuestion
  isLoading: boolean
  handleNextStep: () => void
  handleFeedback(): Promise<void>
}

export default function OpenEndedAnalysisQuestion({
  currentQuestion,
  isLoading,
  handleNextStep,
  handleFeedback,
}: OpenEndedAnalysisQuestionProps) {
  const { learningSession } = useLearningSession()
  const { currentAnswer, openAnswer, setOpenAnswer, checkOpenAnswer, setSelectedOption } =
    useQuestionTypes({
      currentQuestion,
    })

  useEffect(() => {
    if (
      (currentQuestion.type === QUESTION_TYPE.OPEN_ENDED ||
        currentQuestion.type === QUESTION_TYPE.ANALYSIS) &&
      currentAnswer?.answer &&
      !currentAnswer?.feedback
    ) {
      handleFeedback()
    }
  }, [currentAnswer])

  return (
    <div className="mt-12 grid gap-4">
      <div className="flex items-center gap-4">
        <p className="text-lg font-medium">
          <Latex>{currentQuestion.question}</Latex>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <Textarea
          className="w-full"
          placeholder="Escribí tu respuesta acá"
          value={openAnswer}
          onChange={(e) => setOpenAnswer(e.target.value)}
        />
      </div>
      {!currentAnswer?.answer && (
        <div className="flex flex-col items-end gap-8">
          <Button
            disabled={!openAnswer}
            className="text-md w-fit"
            onClick={() => checkOpenAnswer(openAnswer, currentQuestion.index, currentQuestion.type)}
          >
            Enviar y verificar
          </Button>
        </div>
      )}

      {currentAnswer && (
        <div className="flex flex-col items-end gap-8">
          {currentAnswer?.score !== undefined ? (
            <>
              {learningSession.step < 7 ? (
                <Button
                  className="text-md w-fit"
                  onClick={() => {
                    setSelectedOption(null)
                    setOpenAnswer("")
                    handleNextStep()
                  }}
                >
                  Siguiente
                </Button>
              ) : (
                <Button
                  className="text-md w-fit"
                  onClick={() => {
                    setSelectedOption(null)
                    setOpenAnswer("")
                    handleNextStep()
                  }}
                  disabled={isLoading}
                >
                  {isLoading && <Loader2 className="mr-2 animate-spin" />}
                  Finalizar
                </Button>
              )}
            </>
          ) : (
            <Button disabled={isLoading} className="text-md w-fit" onClick={handleFeedback}>
              {isLoading && <Loader2 className="mr-2 animate-spin" />}
              Verificar
            </Button>
          )}
          {currentAnswer?.score !== undefined ? (
            <Card className="flex w-full flex-col items-start gap-4 rounded-xl border">
              <CardContent className="mt-6 flex w-full flex-wrap items-center justify-between gap-2">
                <span className="text-lg font-medium">Resultado</span>
                <div className="flex flex-col items-center gap-4 sm:flex-row sm:gap-8">
                  <Badge
                    variant="default"
                    className="flex w-fit items-center justify-center gap-1 bg-orange-400 text-xl"
                  >
                    {currentAnswer?.score} / 10
                  </Badge>
                </div>
              </CardContent>
              <CardFooter>
                <p className="text-sm font-light">{currentAnswer?.feedback}</p>
              </CardFooter>
            </Card>
          ) : null}
        </div>
      )}
    </div>
  )
}
