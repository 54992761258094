import { client } from "@/config/client"
import { type MindMapInfo } from "./getAll"

type SaveMindmapPayload = {
  name: string
  type: "MINDMAP"
  content: string // markdown
}

interface SaveMindmapResponse {
  activity: MindMapInfo
}

export default function save(data: SaveMindmapPayload) {
  return client.post<SaveMindmapResponse, SaveMindmapPayload>("/v1/activities", data)
}
