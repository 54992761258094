import { type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "flashcards",
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: FlashcardsDashboardPage } = await import("@/pages/flashcards/dashboard")
        return { Component: FlashcardsDashboardPage }
      },
    },
    {
      path: ":flashcardId",
      lazy: async () => {
        const { default: FlashcardGamePage } = await import("@/pages/flashcards/game")
        return { Component: FlashcardGamePage }
      },
    },
  ],
}

export default routes
