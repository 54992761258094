import { QueryClientProvider } from "@tanstack/react-query"
import { RouterProvider } from "react-router-dom"
import { queryClient } from "../config/query"
import router from "./router"
import { LDProvider } from "launchdarkly-react-client-sdk"
import { Toaster } from "@/components/ui/sonner"
import "./app.css"

export default function App() {
  return (
    <LDProvider clientSideID={import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster richColors position="top-center" />
      </QueryClientProvider>
    </LDProvider>
  )
}
