import Main from "@/layouts/main"
import Dashboard from "@/app/routes/dashboard"
import Admin from "@/app/routes/admin"
import Challenges from "@/app/routes/challenges"
import Flashcards from "@/app/routes/flashcards"
import Profile from "@/app/routes/user/my-profile"
import Diagnoses from "@/app/routes/diagnoses"
import Documents from "@/app/routes/documents"
import LearningPaths from "@/app/routes/learning-paths"
import NewStudySession from "@/app/routes/new-study-session"

import Protected from "@/modules/auth/features/protected-route"
import { Navigate, type RouteObject } from "react-router-dom"
import { Loader } from "@/modules/app/components/loader"
import Tools from "@/app/routes/tools"
import Tito from "@/app/routes/tito"
import ErrorBoundaryPage from "./error-boundary"

const routes: RouteObject = {
  element: (
    <Protected redirect="/login" isAuth>
      <Loader>
        <Main />
      </Loader>
    </Protected>
  ),
  children: [
    Admin,
    Dashboard,
    Challenges,
    LearningPaths,
    Flashcards,
    NewStudySession,
    Profile,
    Diagnoses,
    Documents,
    Tools,
    Tito,
    {
      path: "/",
      element: <Navigate to="/dashboard" replace />,
    },
  ],
  ErrorBoundary: () => <ErrorBoundaryPage />,
}

export default routes
