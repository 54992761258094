const features = [
  {
    icon: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/heart_icon.png",
    name: "Vidas ilimitadas",
    description: "Definí tu ritmo de aprendizaje.",
  },
  {
    icon: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/adn_icon.png",
    name: "Diagnósticos ilimitados",
    description: "Hacé evaluaciones personalizadas sin restricciones.",
  },
  {
    icon: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/question_icon.png",
    name: "Soporte privilegiado",
    description: "Accedé a  grupos y mejores amigos en redes.",
  },
  {
    icon: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/loudspeaker_icon.png",
    name: "Sin anuncios",
    description: "Aprendé sin interrupciones",
  },
]
export default features
