import React, { useState } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { FileItem } from "@/modules/flashcards/components/create-modal/file-item"
import { SelectFileDnd } from "@/modules/flashcards/components/create-modal/select-file-dnd"
import { useUser } from "@/modules/user/stores"
import type { Document } from "@/services/api/documents/get"
import { Loader2, Sparkles } from "lucide-react"
import { useForm } from "react-hook-form"
import type { CreateSessionSchemaType } from "../../validations/subthemes/create-session"
import { createSessionSchema } from "../../validations/subthemes/create-session"
import { zodResolver } from "@hookform/resolvers/zod"
import { useProcessSession } from "../../queries/use-process-session"
import { useLearningSession } from "../../stores/use-learning-session"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import { Loader } from "@/components/loader"
import type { ProcessSessionResponse } from "@/services/api/documents/diagnoses/process-session"
import { ScrollArea } from "@/components/ui/scroll-area"
import { toast } from "sonner"
import { isClientError } from "@educabot/fetcher"
import useBugSnag from "@/app/hooks/useBugSnag"

export function CreateSession(): React.ReactNode {
  const [, setFile] = useState<File | null>(null)
  const [selectedDoc, setSelectedDoc] = useState<Document | null>(null)
  const processSession = useProcessSession()
  const { selectedSubtheme } = useLearningPaths()
  const { user } = useUser()
  const { documents: userDocs } = user
  const { learningSession, setLearningSession } = useLearningSession()
  const { notify } = useBugSnag()

  const sortedUserDocs = userDocs.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )

  const form = useForm<CreateSessionSchemaType>({
    resolver: zodResolver(createSessionSchema),
    defaultValues: {
      startPage: 1,
      endPage: 40,
    },
  })

  async function onProcessDocument(values: CreateSessionSchemaType) {
    if (!selectedDoc) return
    try {
      const { data } = await processSession.mutateAsync({
        documentId: selectedDoc.id,
        startPage: values.startPage,
        endPage: values.endPage,
        subThemeId: selectedSubtheme.id,
      })
      const dataParsed = JSON.parse(data) as ProcessSessionResponse
      setLearningSession({
        ...learningSession,
        step: 1,
        questions: dataParsed.questions,
      })
    } catch (error) {
      notify(error)

      toast.error("Algo salió mal", {
        description: isClientError(error)
          ? error.message
          : "Por favor intentalo de nuevo más tarde.",
      })
    }
  }

  const onSelectDoc = (doc: Document) => {
    if (processSession.isPending) return
    setSelectedDoc(doc)
  }

  if (processSession.isPending) {
    return (
      <section className="mx-auto mt-48 flex flex-col items-center justify-center space-y-6">
        <Loader size="xl" />
        <h2 className="text-center text-xl font-medium text-muted-foreground">
          Estamos creando tu evaluación...
        </h2>
      </section>
    )
  }

  return (
    <section className="space-y-6">
      <h2 className="text-left text-lg font-semibold">¿Como te gustaría arrancar tu sesión?</h2>
      <section className="space-y-2">
        <h3 className="text-sm font-medium">Mis documentos</h3>

        <ScrollArea className="h-[30vh] w-full">
          <section className="grid gap-2">
            {sortedUserDocs.map((doc) => (
              <FileItem
                key={doc.id}
                fileName={doc.filename || "-"}
                isSelected={selectedDoc?.id === doc.id}
                onClick={() => onSelectDoc(doc)}
              />
            ))}
          </section>
        </ScrollArea>
      </section>
      <SelectFileDnd setFile={setFile} onSelectDoc={onSelectDoc} />
      <form
        onSubmit={form.handleSubmit(onProcessDocument)}
        className="flex flex-col justify-end gap-3"
      >
        {document && (
          <>
            <section className="grid grid-cols-1 gap-2 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <Label htmlFor="start">Desde</Label>
                <Input
                  {...form.register("startPage", { valueAsNumber: true })}
                  type="number"
                  placeholder="1"
                  disabled={processSession.isPending}
                />
                {form.formState.errors.startPage && (
                  <p className="text-sm text-destructive">
                    {form.formState.errors.startPage.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <Label htmlFor="end">Hasta</Label>
                <Input
                  {...form.register("endPage", { valueAsNumber: true })}
                  type="number"
                  placeholder="40"
                  disabled={processSession.isPending}
                />
                {form.formState.errors.endPage && (
                  <p className="text-sm text-destructive">
                    {form.formState.errors.endPage.message}
                  </p>
                )}
              </div>
            </section>
            {form.formState.errors.root ? (
              <p className="text-sm text-destructive">{form.formState.errors.root.message}</p>
            ) : (
              <p className="text-sm text-muted-foreground">
                Debes elegir un rango máximo 40 páginas.
              </p>
            )}
          </>
        )}
        <Button
          type="submit"
          className="w-full sm:w-auto"
          disabled={!document || processSession.isPending || !form.formState.isValid}
        >
          {processSession.isPending ? (
            <Loader2 className="!size-7 animate-spin" />
          ) : (
            <>
              <span>Generar</span>
              <Sparkles className="h-5 w-5" />
            </>
          )}
        </Button>
      </form>
    </section>
  )
}
