import { useState } from "react"
import { Button } from "@/components/ui/button"
import EmailLoginForm from "@/modules/login/components/email-form"
import GoogleLoginButton from "@/modules/login/components/google-login-button"
import useGoogleAuthUrl from "@/modules/login/hooks/use-auth-url"
import isProd from "@/utils/is-prod"

export default function LoginPage() {
  const [showEmailLogin, setShowEmailLogin] = useState(false)

  const url = useGoogleAuthUrl({
    clientId: import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID,
    redirectUri: import.meta.env.VITE_GOOGLE_AUTH_REDIRECT_URI,
  })

  const handleGoogleLogin = () => {
    window.location.href = url
  }

  return (
    <section className="min-h-screen w-full lg:overflow-hidden">
      <header className="flex w-full justify-end p-5">
        <img
          width={148}
          height={22}
          src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/tich_logo.png"
          alt=""
        />
      </header>
      <section className="mx-auto flex h-full max-w-5xl flex-col justify-center lg:flex-row-reverse lg:items-center lg:justify-center lg:gap-16">
        {!showEmailLogin ? (
          <>
            <img
              src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/sign-in-image.png"
              className="max-h-[400px] object-contain"
            />
            <section className="flex flex-col px-2">
              <h1 className="mb-16 text-center text-2xl font-bold text-black/85">
                Sumate a miles de estudiantes que aprueban sus exámenes junto a TichAI
              </h1>
              <GoogleLoginButton onClick={handleGoogleLogin} />
              {!isProd && (
                <div className="text-center">
                  <Button
                    variant="link"
                    className="text-primary"
                    onClick={() => setShowEmailLogin(true)}
                  >
                    Continuar con Email
                  </Button>
                </div>
              )}
              <p className="my-4 text-center text-sm font-normal text-black/65 lg:hidden">
                Para una mejor experiencia, asegurate de continuar en tu navegador de preferencia
              </p>
            </section>
          </>
        ) : (
          <section className="w-full max-w-xl px-4">
            <Button variant="link" className="-ml-4 mb-4" onClick={() => setShowEmailLogin(false)}>
              Volver
            </Button>
            <EmailLoginForm />
          </section>
        )}
      </section>
    </section>
  )
}
