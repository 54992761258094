import { Button } from "@/components/ui/button"
import features from "../constants/features"

interface PlanPresentationStepProps {
  next: () => void
}

export default function PlanPresentationStep({ next }: PlanPresentationStepProps) {
  return (
    <div className="mx-auto px-4 py-5">
      <div className="mb-14 grid gap-2 text-center">
        <h1 className="text-2xl font-bold text-black/85">Obtené Tich Premium</h1>
        <p className="text-lg text-black/85">
          ¡Los usuarios premium tienen{" "}
          <span className="font-semibold">5 veces más probabilidades</span> de aprobar sus exámenes!
        </p>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center gap-2 p-3 sm:p-4">
            <div className="rounded-lg p-2">
              <img src={feature.icon} className="h-10 w-9 object-contain" />
            </div>

            <div>
              <h3 className="text-lg font-semibold text-gray-900">{feature.name}</h3>
              <p className="text-base text-black/60">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-12 flex justify-center">
        <Button className="w-full max-w-lg py-2.5 text-sm font-medium" onClick={next}>
          Ver planes
        </Button>
      </div>
    </div>
  )
}
