import { client } from "@/config/client"

export type MindMapInfo = {
  id: string
  name: string
  index: string | null
  content: string
}

export type GetMindMapsInfoResponse = {
  mindmaps: MindMapInfo[]
  more: boolean
}

export type GetAllMindmapsParams = {
  limit: number
  offset: number
}

function getAll({ limit, offset }: GetAllMindmapsParams) {
  return client.get<GetMindMapsInfoResponse>(`/v1/mindmaps`, { params: { limit, offset } })
}

export default getAll
