import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import PlanPresentationStep from "./plan-presentation-step"
import PlanSelectionStep from "./plan-selection-step"
import usePlanModal from "../stores/use-plan-modal"

interface PlanDialogProps {
  children: React.ReactNode
}

export default function PlanDialog({ children }: PlanDialogProps) {
  const { isOpen, step, setClose, setStep } = usePlanModal()

  const handleNext = () => {
    setStep(1)
  }

  const handleDialogClose = (open: boolean) => {
    setClose()
    if (!open) {
      setStep(0)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleDialogClose}>
      {children}
      <DialogContent className="flex w-[90%] max-w-[90%] rounded-md">
        <section className="relative size-full">
          {step === 0 && <div className="bg-plan-modal absolute inset-0 z-0" />}

          {step === 1 && (
            <DialogHeader className="items-center">
              <DialogTitle className="text-2xl font-bold">
                Elegí un plan y prepararte con los mejores métodos de estudio.
              </DialogTitle>
            </DialogHeader>
          )}
          <div className="relative z-10 flex">
            {step === 0 && <PlanPresentationStep next={handleNext} />}
            {step === 1 && <PlanSelectionStep />}
          </div>
          {step === 1 && (
            <DialogFooter>
              <p className="w-full text-center text-xs text-black/60">
                <strong>Podés cancelar tu plan en cualquier momento sin costos adicionales.</strong>{" "}
                Tu suscripción se renovará automáticamente por el mismo período de tiempo si no es
                cancelada al menos 24 horas antes de que termine el período en curso.
              </p>
            </DialogFooter>
          )}
        </section>
      </DialogContent>
    </Dialog>
  )
}
