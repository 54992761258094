import type { Plan } from "../types/plan"
import React from "react"
import { cn } from "@/utils/cn"
import { Badge } from "@/components/ui/badge"
import { useMediaQuery } from "usehooks-ts"
import { Button } from "@/components/ui/button"

interface PlanCardProps {
  plan: Plan
  selected: boolean
  handlePlanCard: (plan: Plan) => void
  onClickBtn: () => void
  userCountry: string
  isBestOffer: boolean
}

export const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  selected,
  handlePlanCard,
  onClickBtn,
  userCountry,
  isBestOffer,
}) => {
  const isDesktopSize = useMediaQuery("(min-width: 768px)")
  const usdToArs = 1350
  const price = new Intl.NumberFormat(userCountry === "argentina" ? "es-AR" : "en-US", {
    style: "currency",
    currency: userCountry === "argentina" ? "ARS" : "USD",
  }).format(userCountry === "argentina" ? plan.priceInUsd * usdToArs : plan.priceInUsd)

  return (
    <section
      onClick={() => handlePlanCard(plan)}
      className={cn(
        "group relative flex min-h-[180px] w-full cursor-pointer flex-col rounded-xl p-4 md:max-w-[328px]",
        "border",
        !isDesktopSize && selected
          ? "ring-none border-none bg-orange-50 shadow-[0px_0px_4px_1px_#FB923C] shadow-primary-500 ring-0"
          : "border-gray-200 hover:border-orange-200",
      )}
    >
      <section className="space-y-4">
        <section className="flex w-full flex-wrap items-center justify-between gap-2">
          <section className="inline-flex items-center gap-1">
            <img
              src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/tich_ai_hat_logo.svg"
              className="size-4 -rotate-[25deg] grayscale"
            />
            <p className="text-xs font-semibold text-black/30">Premium</p>
          </section>
          {isBestOffer && (
            <Badge className="w-fit rounded-[2px] uppercase leading-[14px]">Mejor oferta</Badge>
          )}
        </section>
        <h1 className="text-xl font-bold text-black">{plan.name}</h1>
        {userCountry === "argentina" ? (
          <h2 className={cn("flex text-2xl font-bold text-primary", selected && "!text-shadow")}>
            {price} {plan.periodInMonths === 12 ? "anual" : "mes"}
          </h2>
        ) : (
          <section className="flex items-center justify-between">
            <h2 className={cn("flex text-xl font-bold text-primary", selected && "!text-shadow")}>
              <span className="mr-1 mt-1 text-xs uppercase">
                {userCountry === "argentina" ? "ars" : "usd"}
              </span>
              {plan.priceInUsd} {plan.periodInMonths === 12 ? "anual" : "mes"}
            </h2>
            <p className={cn("text-sm text-black/60", selected && "font-semibold")}>
              {plan.description}
            </p>
          </section>
        )}
        <section className="flex flex-col gap-2">
          <div className="h-px w-full bg-black/30" />
          <div>
            {plan.items.map((item, index) => (
              <p key={index} className="text-sm text-black/60">
                {item}
              </p>
            ))}
          </div>
        </section>
        {isDesktopSize && (
          <Button className="w-full" onClick={onClickBtn}>
            Comenzar
          </Button>
        )}
      </section>
    </section>
  )
}
