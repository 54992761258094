import { client } from "@/config/client"
import type { SessionQuestion } from "../learning-paths/get"
import type { LearningSessionAnswer } from "./create-feedback"

export interface SessionReview {
  positive: string
  negative: string
  strategies: string[]
}

export interface CreateReviewData {
  questions: SessionQuestion[]
  answers: LearningSessionAnswer[]
  subThemeId: string
}

export interface CreateReviewResponse {
  review: SessionReview
}

export function createReview(data: CreateReviewData) {
  return client.post<string, CreateReviewData>("/v1/diagnoses/reviews", data)
}
