import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { useUser } from "@/modules/user/stores/use-user"
import { Link, useNavigate } from "react-router-dom"
import { GraduationCap, Flame, Heart, ArrowRight, Infinity } from "lucide-react"
import GoogleMeet from "@/assets/google-meet"
import MetricCard from "./metric-card"
import ChatTito from "@/modules/tito/components/chat-tito"
import CountUp from "react-countup"
import ActivityDashboardCard from "@/modules/dashboard/components/activity-card"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import usePlanModal from "@/modules/plans/stores/use-plan-modal"
import UploadDocumentModal from "@/modules/dashboard/components/upload-doc-modal"
import { useMediaQuery } from "usehooks-ts"

export default function DashboardPage() {
  const { user } = useUser()
  const navigate = useNavigate()
  const { setOpen } = usePlanModal()
  const { selectedLearningPath } = useLearningPaths()
  const isMdSize = useMediaQuery("(min-width: 768px)")

  const streak =
    user.learningSessions.reduce((acc, session, index, array) => {
      if (index === 0) return 1
      const prevDate = new Date(array[index - 1].createdAt)
      const currentDate = new Date(session.createdAt)
      const diff = currentDate.getDate() - prevDate.getDate()
      if (diff === 1) return acc + 1
      else return acc
    }, 0) ?? 0

  const lives = user.lives ?? 0
  const isPremium = user.plan === "PRO"
  const points = user.userPoints.reduce((acc, curr) => acc + curr.points, 0) ?? 0

  const handleOnClickFlashcards = () => {
    navigate("/flashcards")
  }

  return (
    <main className="flex size-full flex-col gap-4 px-4 py-6 lg:flex-row lg:gap-8 lg:p-10">
      <div className="flex flex-col justify-between gap-4 lg:w-[60%] lg:gap-8">
        <div className="hidden lg:block">
          {user.documents.length === 0 && <UploadDocumentModal />}
        </div>
        <div className="flex flex-col justify-between md:flex-row">
          <span className="px-2 text-2xl font-medium">¡Hola, {user.name.split(" ")[0]}!</span>
          <span className="hidden md:block">
            {!isPremium && isMdSize && (
              <Button
                variant="link"
                className="font-medium text-gray-900 !no-underline"
                onClick={() => setOpen()}
              >
                ¿Querés ser premium?{" "}
                <span className="ml-1 font-bold text-primary">Activalo acá</span>
              </Button>
            )}
            {!isPremium && !isMdSize && (
              <Button
                variant="link"
                className="font-medium text-gray-900 !no-underline"
                onClick={() => setOpen()}
              >
                ¿Querés ser premium?{" "}
                <span className="ml-1 font-bold text-primary">Activalo acá</span>
              </Button>
            )}
          </span>
        </div>

        <Card className="order-3 w-full p-2 shadow-md">
          <CardHeader className="px-4 py-3">
            <CardTitle className="mb-2 font-medium text-gray-800 lg:text-lg">
              Tus métricas
            </CardTitle>
          </CardHeader>
          <CardContent className="px-4">
            <div className="grid grid-cols-3 gap-6 sm:gap-12 md:grid-cols-4">
              <MetricCard title={"Puntos"} icon={GraduationCap}>
                <p className="mt-2 text-lg font-medium sm:text-2xl">
                  <CountUp end={points} duration={0.2} />
                </p>
              </MetricCard>
              <MetricCard title={"Racha"} icon={Flame}>
                <p className="mt-2 text-lg font-medium sm:text-2xl">
                  <CountUp end={streak} duration={0.2} /> {streak === 1 ? "día" : "días"}
                </p>
              </MetricCard>
              <MetricCard title={"Vidas"} icon={Heart}>
                {isPremium ? (
                  <Infinity className="mt-2 size-8" />
                ) : (
                  <p className="mt-2 text-lg font-medium sm:text-2xl">
                    <CountUp end={lives} duration={0.2} />
                  </p>
                )}
              </MetricCard>
            </div>
          </CardContent>
        </Card>

        <Button
          asChild
          className="order-4 flex w-full justify-between gap-2 bg-fuchsia-700 px-4 py-4 text-left shadow-md hover:bg-fuchsia-800 sm:py-7 lg:px-8"
        >
          <Link to="http://meet.google.com/ors-sdve-rjo">
            <h1 className="text-sm font-medium md:block lg:text-base">¡Sumate a estudiar!</h1>
            <div className="flex items-center gap-2 text-sm font-medium lg:gap-4 lg:text-base">
              <GoogleMeet />
              Iniciar pomodoro
              <ArrowRight strokeWidth={3} className="h-4 w-4 lg:h-5 lg:w-5" />
            </div>
          </Link>
        </Button>
        <div className="order-4 mb-6 mt-12 flex flex-col space-y-6 lg:hidden">
          <h2 className="text-lg font-semibold text-black/85">{selectedLearningPath.name}</h2>
          {user.documents.length === 0 && <UploadDocumentModal />}
        </div>
        <div className="order-5 grid h-full w-full gap-4 lg:grid-cols-2 lg:gap-8">
          <ActivityDashboardCard
            title="Desafíos"
            description="Poné a prueba tus conocimientos como si fuese un juego. Subí tu pdf y respondé preguntas."
            handleOnClick={() => navigate("/challenges")}
            variant="challenge"
          />
          <ActivityDashboardCard
            title="Flashcards"
            description="Recordá conceptos clave con este método de repetición espaciada y combatí la curva del olvido."
            handleOnClick={handleOnClickFlashcards}
            variant="flashcard"
          />
        </div>
      </div>
      <div className="hidden lg:flex lg:w-[40%]">
        <Card className="h-full w-full overflow-hidden rounded-lg shadow-md">
          <ChatTito />
        </Card>
      </div>
    </main>
  )
}
