import createLevelSession from "@/services/api/challenges/create-level-session"
import { useMutation, useQueryClient } from "@tanstack/react-query"

const useCreateLevelSession = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createLevelSession,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-user"] })
    },
  })
  return mutation
}

export default useCreateLevelSession
