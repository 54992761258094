import challenges from "./challenges"
import { user } from "./users"
import { admin } from "./admin"
import { documents } from "./documents"
import { learnignPaths } from "./learning-paths"
import { stripe } from "./stripe"
import { mindmaps } from "./mindmaps"
import diagnoses from "./diagnoses"

export const api = {
  challenges,
  documents,
  learnignPaths,
  stripe,
  user,
  admin,
  diagnoses,
  mindmaps,
}
