import { loadStripe } from "@stripe/stripe-js"
import { useUser } from "@/modules/user/stores"
import { useCreateStripeSession } from "@/modules/plans/queries"
import isProd from "@/utils/is-prod"
import { useState } from "react"
import type { Plan } from "../types/plan"
import { PlanCard } from "./plan-card"
import { Button } from "@/components/ui/button"
import { Loader2 } from "lucide-react"
import { cn } from "@/lib/utils"
import { useMediaQuery } from "usehooks-ts"

export default function PlanSelectionStep() {
  const { user } = useUser()
  const [loading, setLoading] = useState(false)
  const createStripeSession = useCreateStripeSession()
  const [monthlyPlansToggled, setMonthlyPlansToggled] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<Plan>(plans[0])
  const plansToShow = monthlyPlansToggled ? plans : plans.filter((p) => p.periodInMonths === 12)
  const isDesktopSize = useMediaQuery("(min-width: 768px)")

  async function handleGoToStripe(plan: Plan) {
    setLoading(true)
    const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)
    const data = await createStripeSession.mutateAsync(plan.stripeId)
    setLoading(false)
    await stripe?.redirectToCheckout({ sessionId: data.data.sessionId })
  }

  function handlePlanCard(plan: Plan) {
    setSelectedPlan(plan as Plan)
  }

  return (
    <div className="w-full px-3 py-4 sm:px-4 sm:py-6">
      <div
        className={cn(
          "justify-center space-y-6 md:flex md:gap-6 md:space-y-0",
          monthlyPlansToggled && "md:grid-cols-3",
        )}
      >
        {plansToShow.map((plan, index) => (
          <PlanCard
            key={index}
            plan={plan}
            selected={selectedPlan?.name === plan.name}
            handlePlanCard={handlePlanCard}
            onClickBtn={() => handleGoToStripe(plan)}
            userCountry={user.country}
            isBestOffer={plan.isBestOffer}
          />
        ))}
      </div>

      <div
        className={cn(
          "mt-4 space-y-4 sm:mt-6 sm:space-y-6",
          isDesktopSize && "flex justify-center",
        )}
      >
        {!isDesktopSize && (
          <Button
            className="w-full rounded-xl bg-orange-500 py-2 text-sm font-semibold text-white hover:bg-orange-600 sm:py-3 sm:text-base"
            disabled={!selectedPlan}
            onClick={() => handleGoToStripe(selectedPlan)}
          >
            {loading && <Loader2 className="h-5 w-5 animate-spin" />}
            {loading ? "Cargando..." : "Comenzar"}
          </Button>
        )}

        <Button
          variant={isDesktopSize ? "ghost" : "secondary"}
          className={cn("w-full", isDesktopSize && "max-w-lg")}
          onClick={() => setMonthlyPlansToggled(!monthlyPlansToggled)}
        >
          {monthlyPlansToggled ? "Volver" : "Ver todos los planes"}
        </Button>
      </div>
    </div>
  )
}

const plans: Plan[] = [
  {
    id: 1,
    name: "Individual Mensual",
    description: "",
    priceInUsd: 5.99,
    periodInMonths: 1,
    students: 1,
    stripeId: isProd ? "price_1PDFMSJvm5wiWXqfqpUeJ29t" : "price_1Png3lJvm5wiWXqfL1PJ5hX9",
    isBestOffer: false,
    items: ["1 cuenta premium", "Cancelá cuando quieras"],
  },
  {
    id: 2,
    name: "Individual",
    description: "USD 4,17/mes",
    priceInUsd: 49.99,
    periodInMonths: 12,
    students: 1,
    stripeId: isProd ? "price_1PDFK7Jvm5wiWXqfhtfhxLOz" : "price_1Png3lJvm5wiWXqfL1PJ5hX9",
    isBestOffer: false,
    items: ["1 cuenta premium", "Cancelá cuando quieras"],
  },
  {
    id: 3,
    name: "Amigos",
    description: "USD 5,85/mes",
    priceInUsd: 69.42,
    periodInMonths: 12,
    students: 2,
    stripeId: isProd ? "price_1PDGGwJvm5wiWXqfw6nfGbpT" : "price_1Png3lJvm5wiWXqfL1PJ5hX9",
    isBestOffer: true,
    items: ["2 cuentas premium", "Cancelá cuando quieras"],
  },
]
