import type { LearningPath, Subtheme, Theme } from "@/services/api/learning-paths/get"
import { create } from "@/app/state"
import type { StateCreator } from "zustand"
import { persist } from "zustand/middleware"

export interface LearningPathsState {
  learningPaths: LearningPath[]
  selectedLearningPath: LearningPath
  selectedTheme: Theme
  selectedSubtheme: Subtheme
  setLearningPaths: (learningPaths: LearningPath[]) => void
  setSelectedLearningPath: (learningPath: LearningPath) => void
  setSelectedTheme: (theme: Theme) => void
  setSelectedSubtheme: (subtheme: Subtheme) => void
}

const initialState = {
  selectedLearningPath: {
    id: "",
    name: "",
    description: "",
    score: 0,
    additionalInfo: "",
    bibliography: "",
    userId: "",
    createdAt: "",
    updatedAt: "",
  },
  selectedTheme: {
    id: "",
    index: 0,
    subthemes: [],
    name: "",
    learningPathId: "",
    score: 0,
    createdAt: "",
    updatedAt: "",
  },
  selectedSubtheme: {
    id: "",
    index: 0,
    name: "",
    learningPathThemeId: "",
    score: null,
    sessionQuestions: [],
    sessions: [],
    components: null,
    createdAt: "",
    updatedAt: "",
  },
  learningPaths: [],
}

const state: StateCreator<LearningPathsState> = (set) => ({
  ...initialState,
  setLearningPaths: (learningPaths: LearningPath[]) => set({ learningPaths }),
  setSelectedLearningPath: (learningPath: LearningPath) =>
    set({ selectedLearningPath: learningPath }),
  setSelectedTheme: (theme: Theme) => set({ selectedTheme: theme }),
  setSelectedSubtheme: (subtheme: Subtheme) => set({ selectedSubtheme: subtheme }),
})

export const useLearningPaths = create<LearningPathsState>()(
  persist(state, {
    name: "learnign-path",
    partialize: (state) => ({
      selectedLearningPath: state.selectedLearningPath,
      selectedSubtheme: state.selectedSubtheme,
      selectedTheme: state.selectedTheme,
    }),
  }),
)
