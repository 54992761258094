import { cn } from "@/lib/utils"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import { useCreateDocument } from "@/modules/documents/queries/use-create"
import { usePresignedUrl } from "@/modules/documents/queries/use-create-presigned-url"
import type { Document } from "@/services/api/documents/get"
import { FileUp } from "lucide-react"
import React from "react"
import { useDropzone } from "react-dropzone"
import { toast } from "sonner"
import { isClientError } from "@educabot/fetcher"
import useBugSnag from "@/app/hooks/useBugSnag"

interface Props {
  setFile: (file: File) => void
  onSelectDoc: (doc: Document) => void
}

export const SelectFileDnd: React.FC<Props> = ({ setFile, onSelectDoc }) => {
  const { selectedLearningPath } = useLearningPaths()
  const createDocument = useCreateDocument()
  const presignedUrl = usePresignedUrl()
  const { notify } = useBugSnag()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
  })

  function onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length <= 0) return
    setFile(acceptedFiles[0])
    handleFileUpload(acceptedFiles[0])
  }

  async function onCreateDocument(fileName: string, objectId: string) {
    try {
      const { data } = await createDocument.mutateAsync({
        file: fileName,
        learningPathId: selectedLearningPath.id,
        objectId,
      })
      onSelectDoc(data)
    } catch (error) {
      notify(error)

      toast.error("Algo salió mal", {
        description: isClientError(error)
          ? error.message
          : "Por favor intentalo de nuevo más tarde.",
      })
    }
  }

  async function handleFileUpload(file: File) {
    if (!file) return
    try {
      const { data } = await presignedUrl.create.mutateAsync()
      await presignedUrl.uploadFile.mutateAsync({
        url: data.url,
        file,
      })
      await onCreateDocument(file.name, data.objectId)
    } catch (error) {
      notify(error)

      toast.error("Algo salió mal", {
        description: isClientError(error)
          ? error.message
          : "Por favor intentalo de nuevo más tarde.",
      })
    }
  }

  return (
    <section
      {...getRootProps()}
      className={cn(
        "cursor-pointer rounded-lg border-2 border-dashed p-6 transition-colors",
        isDragActive ? "border-primary" : "hover:bg-muted/50",
      )}
    >
      <input {...getInputProps()} />
      <section className="flex flex-col items-center justify-center text-center">
        <FileUp className="mb-3 h-8 w-8 text-muted-foreground" />
        <p className="mb-2 text-sm text-muted-foreground">
          {isDragActive
            ? "Suelta el archivo aquí"
            : "Arrastra y suelta tu PDF aquí o haz clic para seleccionar"}
        </p>
        <p className="text-sm font-medium text-primary">Seleccionar archivo</p>
      </section>
    </section>
  )
}
