export type StreamChunk = {
  value: Uint8Array
  done: boolean
}

export type ModelChoice = {
  delta?: {
    content?: string
  }
}

export type ModelResponse = {
  choices?: ModelChoice[]
  error?: {
    message: string
  }
}

export class StreamReaderError extends Error {
  constructor(
    message: string,
    public originalError?: unknown,
  ) {
    super(message)
    this.name = "StreamReaderError"
  }
}

const processStreamLine = (line: string): string => {
  try {
    const parsed = JSON.parse(line) as ModelResponse

    if (parsed.error) {
      throw new StreamReaderError(`API Error: ${parsed.error.message}`)
    }

    return parsed.choices?.[0]?.delta?.content || ""
  } catch (err) {
    if (err instanceof StreamReaderError) {
      throw err
    }
    return ""
  }
}

export const readStream = async (
  reader: ReadableStreamDefaultReader<Uint8Array>,
): Promise<string> => {
  const decoder = new TextDecoder()
  let completeMessage = ""

  try {
    while (true) {
      const { value, done } = await reader.read()

      if (done) break

      const text = decoder.decode(value, { stream: true })
      const lines = text.split("\n")

      for (const line of lines) {
        completeMessage += processStreamLine(line)
      }
    }

    return completeMessage
  } catch (error) {
    throw new StreamReaderError(
      "Error processing stream",
      error instanceof Error ? error : undefined,
    )
  } finally {
    reader.releaseLock()
  }
}

export const getMessage = async (
  reader: ReadableStreamDefaultReader<Uint8Array>,
): Promise<string> => {
  try {
    return await readStream(reader)
  } catch (error) {
    await reader.cancel()
    if (error instanceof StreamReaderError) {
      throw error
    }
    throw new StreamReaderError(
      "Error getting message from stream",
      error instanceof Error ? error : undefined,
    )
  }
}
