import type { StateCreator } from "zustand"
import { create as actualCreate } from "zustand"

export const storeResetFns = new Set<() => void>()

export const create = (<T>() => {
  return (createState: StateCreator<T> | ReturnType<StateCreator<T>>) => {
    // We handle both functions and middleware objects
    const wrappedStateCreator: StateCreator<T> = (...args) => {
      const state =
        typeof createState === "function" ? (createState as StateCreator<T>)(...args) : createState
      return state
    }

    const store = actualCreate(wrappedStateCreator)
    storeResetFns.add(() => {
      store.setState(store.getInitialState(), true)
    })
    return store
  }
}) as typeof actualCreate
