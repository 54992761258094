import CollapsableChallengeList from "@/modules/challenges/features/dashboard/collapsable-challenge-list"
import { ChevronLeft, Plus } from "lucide-react"
import { Link, useNavigate } from "react-router-dom"
import PendingChallengesAlert from "@/modules/challenges/components/pending-challenges-alert"
import { Button } from "@/components/ui/button"
import { useNoLifeAlert } from "@/modules/app/stores/use-no-life-alert"
import { useUser } from "@/modules/user/stores"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import useGetUserChallenges from "@/modules/challenges/queries/use-get-user-challenges"
import { useEffect, useState } from "react"
import { isApproved } from "@/modules/challenges/utils/score"
import useGetUser from "@/modules/user/queries/use-get-user"
import EditChallengeModal from "@/modules/challenges/features/dashboard/edit-challenge-modal"
import DeleteChallengeModal from "@/modules/challenges/features/dashboard/delete-challenge-modal"

const ChallengesDashboardPage = () => {
  const navigate = useNavigate()
  const { setIsOpen: openNoLifeAlert } = useNoLifeAlert()
  const { user, setUser } = useUser()
  const { plan, lives } = user
  const [isOpenPendingChallenges, setIsOpenPendingChallenges] = useState<boolean>(false)
  const { selectedLearningPath } = useLearningPaths()
  const { data: userData } = useGetUser()
  const challengesQuery = useGetUserChallenges({
    limit: 100,
    offset: 0,
    learningPathId: selectedLearningPath.id,
  })

  useEffect(() => {
    if (userData?.user) {
      setUser(userData.user)
    }
  }, [userData])

  const { data } = challengesQuery
  const hasAllTheChallengesApproved =
    data &&
    data.challenges.every(({ levels }) => {
      const lastLevel = levels.at(-1)
      const isLastLevelApproved = lastLevel?.status === "completed" && isApproved(lastLevel.score)
      return isLastLevelApproved
    })

  const handleOnAddNewChallenge = () => {
    const canAddNewChallengeAsFreeUser = lives > 0 && hasAllTheChallengesApproved
    const isPro = plan === "PRO"

    if (isPro || canAddNewChallengeAsFreeUser) {
      navigate("/new-study-session?type=challenges")
      return
    }

    if (!isPro && lives > 0 && !hasAllTheChallengesApproved) {
      setIsOpenPendingChallenges(true)
      return
    }

    if (!isPro && lives === 0) {
      openNoLifeAlert(true)
      return
    }
  }

  return (
    <>
      <div className="mx-auto h-full w-full max-w-3xl overflow-hidden px-3 pb-14 pt-8">
        <Link
          to="/dashboard"
          className="flex w-fit items-center justify-center gap-1 text-sm font-bold text-gray-500"
        >
          <ChevronLeft className="text-gray-500" />
          Volver
        </Link>

        <div className="my-5 flex items-center justify-between">
          <h4 className="text-xl font-bold text-black">Desafíos</h4>

          <div className="flex items-center justify-center gap-5">
            {/* <Link
            to=""
            className="flex w-fit items-center justify-center gap-1 text-sm font-bold text-gray-500"
          >
            <ArrowDownUp className="text-gray-500" />
            Reordenar
          </Link> */}

            <Button
              onClick={handleOnAddNewChallenge}
              className="flex w-fit items-center justify-center gap-1 rounded-[8px] bg-orange-500 px-3 py-2 text-sm font-bold text-white transition duration-200 hover:bg-orange-600"
            >
              <Plus className="text-white" />
              Nuevo
            </Button>
          </div>
        </div>

        <CollapsableChallengeList challengesQuery={challengesQuery} />

        <Button
          onClick={handleOnAddNewChallenge}
          className="mt-3 flex w-full items-center justify-center gap-1 rounded-lg border border-[#C7C7CC] bg-transparent py-2 text-sm font-bold text-black transition duration-200 hover:border-gray-400"
        >
          <Plus className="text-black" />
          Nuevo
        </Button>
      </div>

      <PendingChallengesAlert
        isOpen={isOpenPendingChallenges}
        setIsOpen={() => setIsOpenPendingChallenges(!isOpenPendingChallenges)}
      />

      <EditChallengeModal />
      <DeleteChallengeModal />
    </>
  )
}

export default ChallengesDashboardPage
