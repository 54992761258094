import { type StateCreator } from "zustand"
import { create } from "@/app/state"

interface PlanModalState {
  isOpen: boolean
  step: number
  setStep: (step: number) => void
  setOpen: () => void
  setClose: () => void
}

const state: StateCreator<PlanModalState> = (set) => ({
  isOpen: false,
  step: 0,
  setClose: () => set({ isOpen: false }),
  setOpen: () => set({ isOpen: true }),
  setStep: (step) => set({ step }),
})

const usePlanModal = create<PlanModalState>()(state)

export default usePlanModal
